<template>
  <div class="provider-notifications">
    <div class="page-title d-flex py-2 justify-content-between">
      <h1 class="h4 mb-0">Перечень уведомлений поставщика</h1>

      <div class="d-flex align-items-center">
        <BButton class="ml-3" variant="outline-primary" v-b-modal.import
          >Импортировать
        </BButton>
        <BButton
          class="ml-3"
          variant="primary"
          to="/create-provider-notification"
          >Создать
        </BButton>
      </div>
    </div>

    <TableFilter
      :columns="notificationsFields"
      @update-selected-columns="columns = $event"
      @search="filter = $event"
      @clear="clearFilterData"
      @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
    />

    <DefaultTable
      :data="notifications"
      :fields="notificationsFieldsFiltered"
      :filter="filter"
      :loading="loading"
      rowLink="/provider-notifications"
      emptyText="Нет реестров платежей"
      :noLocalSorting="true"
      @sort-change="sorting"
      @pagination-change="paginationChange"
      @per-page-change="perPageChange"
    />

    <FilterSidebar
      id="filter"
      v-model="filterData"
      @apply="updateDataWithFilters"
    />

    <b-modal id="import" centered title="Импорт перечня уведомлений поставщика">
      <div class="p-2 bg-light">
        <div class="font-weight-bold text-muted">
          Файл для импорта <span class="text-danger">*</span>
        </div>

        <b-form-file v-model="importFile" class="mt-1" plain />
      </div>

      <template #modal-footer="{ hide }">
        <b-button variant="outline-primary" @click="hide()"> Отмена </b-button>
        <b-button variant="primary" @click="sendImport">
          Импортировать
        </b-button>
      </template>
    </b-modal>

    <b-modal id="import-success" centered title="Импорт завершён">
      <div class="p-2 bg-light">
        <p>
          Импортирование файла «{{ importFile ? importFile.name : "" }}»
          завершено
        </p>
      </div>

      <template #modal-footer="{ hide }">
        <b-button variant="primary" @click="hide()"> Хорошо </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TableFilter from "@/components/TableFilter";
import DefaultTable from "@/components/Tables/Default";
import FilterSidebar from "@/components/FilterSidebar";

function initialFilterData() {
  return {
    number: "",
    contract_number: "",
    contract_date: ["", ""],
    organization_id: "",
    order_date: ["", ""],
    status: "",
  };
}

export default {
  name: "ProviderNotifications",
  components: {
    TableFilter,
    DefaultTable,
    FilterSidebar,
  },
  computed: {
    ...mapState({
      notifications: (state) => state.providerNotifications.notificationsList,
    }),
    notificationsFieldsFiltered() {
      let res = [];
      this.notificationsFields.forEach((item) => {
        if (this.columns.filter((col) => col === item.label).length) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.notificationsFields;
    },
  },
  data: () => ({
    notificationsFields: [
      {
        key: "number",
        nesting: "id",
        sortable: true,
        label: "№",
        full: "Номер уведомления",
        openDefault: true,
      },
      {
        key: "date",
        sortable: true,
        label: "Дата",
        full: "Дата",
        openDefault: false,
      },
      {
        key: "status",
        sortable: true,
        label: "Статус",
        full: "Статус согласования с получателем (заказчиком)",
        openDefault: true,
      },
      {
        key: "organization",
        nesting: "organization_id",
        sortable: true,
        label: "Получатель (заказчик)",
        full: "Получатель (заказчик)",
        openDefault: true,
      },
      {
        key: "provider.name",
        nesting: "provider_contr_agent_id",
        sortable: true,
        label: "Поставщик",
        full: "Поставщик",
        openDefault: true,
      },
      {
        key: "date_fact_delivery",
        sortable: true,
        label: "Дата поставки",
        full: "Дата поставки",
        openDefault: true,
      },
      {
        key: "contract_number",
        sortable: true,
        label: "№ договора поставки",
        full: "№ договора поставки",
        openDefault: true,
      },
      {
        key: "contract_date",
        sortable: true,
        label: "Дата договора поставки",
        full: "Дата договора поставки",
        openDefault: true,
      },
      {
        key: "delivery_address",
        sortable: true,
        label: "Адрес поставки",
        full: "Адрес поставки",
        openDefault: false,
      },
      {
        key: "car_info",
        sortable: true,
        label: "Марка, гос. номер авто",
        full: "Марка, гос. номер авто",
        openDefault: false,
      },
      {
        key: "driver_phone",
        sortable: true,
        label: "Телефон водителя",
        full: "Телефон водителя",
        openDefault: false,
      },
      {
        key: "responsible_full_name",
        sortable: true,
        label: "Ответственный",
        full: "Ответственный",
        openDefault: false,
      },
      {
        key: "responsible_phone",
        sortable: true,
        label: "Телефон ответственного",
        full: "Телефон ответственного",
        openDefault: false,
      },
      {
        key: "organization_comment",
        sortable: true,
        label: "Комм.",
        full: "Текст комментария",
        openDefault: false,
      },
    ],
    importFile: "",
    columns: [],
    filter: "",
    loading: true,
    filterData: initialFilterData(),
    breadcrumbs: [
      { title: "Главная", link: "/" },
      { title: "Заказ ПО", link: "/" },
      { title: "Перечень уведомлений поставщика" },
    ],
    sortingData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
  }),
  methods: {
    async getData() {
      this.loading = true;
      await this.$store.dispatch("getProviderNotifications");
      this.loading = false;
    },
    clearFilterData() {
      this.filterData = initialFilterData();
      this.getData();
    },
    sendImport() {
      if (this.importFile !== null) {
        this.$bvModal.show("import-success");
        this.$bvModal.hide("import");
      } else {
        this.$bvToast.toast("Вам необходимо загрузить файл формата XML", {
          title: "Вы не прикрепили файл для импорта",
          toaster: "b-toaster-top-right",
          solid: true,
          appendToast: false,
          variant: "danger",
        });
      }
    },
    async updateDataWithFilters(clearSort) {
      this.loading = true;
      if (clearSort) {
        this.sortingData.sort_field = "";
        this.sortingData.sort_order = "";
        this.sortingData.page = "";
      }
      await this.$store.dispatch("getProviderNotifications", {
        ...this.filterData,
        ...this.sortingData,
      });
      this.loading = false;
    },
    sorting(event) {
      this.sortingData.sort_field = event.sortBy;
      this.sortingData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updateDataWithFilters();
    },
    paginationChange(page) {
      this.sortingData.page = page;
      this.updateDataWithFilters();
    },
    perPageChange(perPage) {
      this.sortingData.page = "";
      this.sortingData.per_page = perPage;
      this.updateDataWithFilters();
    },
  },
  async mounted() {
    await this.getData();
    this.notificationsFields.forEach((item) => {
      if (item.openDefault) {
        this.columns.push(item.label);
      }
    });
  },
};
</script>
